import React from 'react'
import {Box, Header, Heading, StyledOcticon, Text, Tooltip} from '@primer/components'
import Layout from '../components/Layout'
import {
    BriefcaseIcon,
    CodeIcon,
    CodeSquareIcon,
    KebabHorizontalIcon,
    MailIcon,
    MarkGithubIcon,
    MilestoneIcon,
    MortarBoardIcon,
    PlusIcon,
    TypographyIcon
} from "@primer/octicons-react";
import '../styles/cv.css'
import 'katex/dist/katex.min.css'
import {Link} from "gatsby";
import {ReactComponent as TelegramIcon} from '../svg/telegram.svg'
import styled from "styled-components";

const StrokeSpan = styled.span`
  fill: ${props => props.strokeColor};
  vertical-align: middle;
  margin-right: 8px;
  font-size: 18px;
`

const log2 = '<span class="katex"><span class="katex-mathml"><math xmlns="http://www.w3.org/1998/Math/MathML"><semantics><mrow><msub><mo><mi>log</mi><mo>⁡</mo></mo><mn>2</mn></msub><mo stretchy="false">(</mo><mtext mathvariant="italic">BigInteger</mtext><mo stretchy="false">)</mo></mrow><annotation encoding="application/x-tex">\\log_2(\\text{\\it BigInteger})</annotation></semantics></math></span><span class="katex-html" aria-hidden="true"><span class="base"><span class="strut" style="height:1em;vertical-align:-0.25em;"></span><span class="mop"><span class="mop">lo<span style="margin-right:0.01389em;">g</span></span><span class="msupsub"><span class="vlist-t vlist-t2"><span class="vlist-r"><span class="vlist" style="height:0.20696799999999996em;"><span style="top:-2.4558600000000004em;margin-right:0.05em;"><span class="pstrut" style="height:2.7em;"></span><span class="sizing reset-size6 size3 mtight"><span class="mord mtight">2</span></span></span></span><span class="vlist-s">​</span></span><span class="vlist-r"><span class="vlist" style="height:0.24414em;"><span></span></span></span></span></span></span><span class="mopen">(</span><span class="mord text"><span class="mord"><span class="mord mathit">B</span><span class="mord mathit">i</span><span class="mord mathit">g</span><span class="mord mathit">I</span><span class="mord mathit">n</span><span class="mord mathit">t</span><span class="mord mathit">e</span><span class="mord mathit">g</span><span class="mord mathit">e</span><span class="mord mathit">r</span></span></span><span class="mclose">)</span></span></span></span>';
const tex = '<span class="katex"><span class="katex-mathml"><math xmlns="http://www.w3.org/1998/Math/MathML"><semantics><mrow><mtext>TeX</mtext></mrow><annotation encoding="application/x-tex">\\TeX</annotation></semantics></math></span><span class="katex-html" aria-hidden="true"><span class="base"><span class="strut" style="height:0.89883em;vertical-align:-0.2155em;"></span><span class="mord text"><span class="mord textrm">T</span><span class="mspace" style="margin-right:-0.1667em;"></span><span class="vlist-t vlist-t2"><span class="vlist-r"><span class="vlist" style="height:0.46782999999999997em;"><span style="top:-2.7845em;"><span class="pstrut" style="height:3em;"></span><span class="mord"><span class="mord textrm">E</span></span></span></span><span class="vlist-s">​</span></span><span class="vlist-r"><span class="vlist" style="height:0.2155em;"><span></span></span></span></span><span class="mspace" style="margin-right:-0.125em;"></span><span class="mord textrm">X</span></span></span></span></span>';

export default function CvEn() {
    return (
        <Layout>
            <Header>
                <Header.Item>
                    <Header.Link href="/" fontSize={2} className="no-print">
                        <StyledOcticon icon={BriefcaseIcon} verticalAlign="middle" size={32} mr={2}/>
                        <span>Andrew Boyarshin</span>
                    </Header.Link>
                    <Text className="only-print" fontSize={3} color={'black'}>Andrew Boyarshin</Text>
                </Header.Item>
                <Header.Item className="only-print" px={1}/>
                <Header.Item>
                    <Header.Link href="https://github.com/andrew-boyarshin" className="no-print">
                        <StyledOcticon icon={MarkGithubIcon} verticalAlign="middle" mr={2}/>
                        <span>GitHub</span>
                    </Header.Link>
                    <StyledOcticon className="only-print" color='black' icon={MarkGithubIcon} verticalAlign="middle"
                                   mr={2}/>
                    <Text className="only-print" color={'black'}>andrew-boyarshin</Text>
                </Header.Item>
                <Header.Item>
                    <Header.Link href="https://t.me/andrew_boyarshin" className="no-print">
                        <StrokeSpan strokeColor={'white'}>
                            <TelegramIcon/>
                        </StrokeSpan>
                        <span>Telegram</span>
                    </Header.Link>
                    <StrokeSpan className="only-print" strokeColor={'black'}>
                        <TelegramIcon/>
                    </StrokeSpan>
                    <Text className="only-print" color={'black'}>andrew_boyarshin</Text>
                </Header.Item>
                <Header.Item>
                    <Header.Link href="mailto:andrew.boyarshin+cv@gmail.com" className="no-print">
                        <StyledOcticon icon={MailIcon} verticalAlign="middle" mr={2}/>
                        <span>E-mail</span>
                    </Header.Link>
                    <StyledOcticon className="only-print" color='black' icon={MailIcon} verticalAlign="middle" mr={2}/>
                    <Text className="only-print" color={'black'}>andrew.boyarshin+cv@gmail.com</Text>
                </Header.Item>
                <Header.Item full/>
                <Header.Item mr={0}>
                    <Text className="black-on-print" fontSize={2}>Resume</Text>
                </Header.Item>
            </Header>
            <Box bg="white" color="black" px={4} pt={6} pb={64}>
                <Text as="p" className="no-print" mb={4}>
                    English version. <Link to="/cv-ru">Switch to Russian</Link>.
                </Text>

                <Heading className="no-break-after" fontSize={4}>
                    <StyledOcticon icon={MilestoneIcon} verticalAlign="middle" mr={1}/> Objective
                </Heading>
                <Text as="p" mb={6}>
                    Be involved in advancing foundational technology stacks, such as OS kernels, managed code VMs,
                    compilers etc.
                    Gain relevant experience and author an original research project to be admissible as <Tooltip
                    aria-label="Bachelor of Science" className="tooltip-dotted">BSc</Tooltip> diploma work.
                </Text>

                <Heading className="no-break-after" fontSize={4}>
                    <StyledOcticon icon={MortarBoardIcon} verticalAlign="middle" mr={1}/> Education
                </Heading>
                <Text as="p">
                    Bachelor of Science student, June 2022 (expected graduation date)
                </Text>
                <Text as="p" mb={6}>
                    Novosibirsk State University, Department of Mathematics and Mechanics, Mathematics and Computer
                    Science
                </Text>

                <Heading className="no-break-after" fontSize={4}>
                    <StyledOcticon icon={MarkGithubIcon} verticalAlign="middle" mr={1}/> Projects
                </Heading>
                <Box className="no-break-block" mb={4}>
                    <Text as="p">
                        <a href="https://github.com/hvanbakel/CsprojToVs2017">CsprojToVs2017</a> — <Tooltip
                        aria-label="#1 by added/removed LoC count" className="tooltip-dotted">Largest</Tooltip> <a
                        href="https://github.com/hvanbakel/CsprojToVs2017/pulls?q=sort%3Aupdated-desc+author%3Aandrew-boyarshin">contributor</a> & <Tooltip
                        aria-label="Can push to master and release new versions"
                        className="tooltip-dotted">co-maintainer</Tooltip>
                    </Text>
                    <Text as="p">
                        Tooling for converting pre-2017 project to the new Visual Studio 2017+ format.
                    </Text>
                    <Text as="p">
                        <StyledOcticon icon={CodeIcon} verticalAlign="middle" mr={1}/> C#, MSBuild
                    </Text>
                </Box>
                <Box className="no-break-block" mb={4}>
                    <Text as="p">
                        <a href="https://github.com/SharpGenTools/SharpGenTools">SharpGenTools</a> — <Tooltip
                        aria-label="#3 by commit count and by added/removed LoC count" className="tooltip-dotted">3rd
                        largest</Tooltip> <a
                        href="https://github.com/SharpGenTools/SharpGenTools/pulls?q=sort%3Aupdated-desc+author%3Aandrew-boyarshin">contributor</a> & <Tooltip
                        aria-label="Can push to master and release new versions"
                        className="tooltip-dotted">co-maintainer</Tooltip>
                    </Text>
                    <Text as="p">
                        Accurate and high performance C++ interop code generator for C#.
                    </Text>
                    <Text as="p">
                        <StyledOcticon icon={CodeIcon} verticalAlign="middle" mr={1}/> C#, MSBuild
                    </Text>
                </Box>
                <Box className="no-break-block" mb={4}>
                    <Text as="p">
                        <a href="https://github.com/andrew-boyarshin/LoaderWatch">LoaderWatch</a> — Author
                    </Text>
                    <Text as="p">
                        Toolbox to assist debugging, tracing and memory inspection of Windows PE image loader (LDR)
                        NTDLL
                        component.
                    </Text>
                    <Text as="p">
                        <StyledOcticon icon={CodeIcon} verticalAlign="middle" mr={1}/> C, C#, Windows kernel-mode driver
                        development, reverse-engineering
                    </Text>
                </Box>
                <Box className="no-break-block" mb={6}>
                    <Text as="p">
                        <a href="https://github.com/andrew-boyarshin/reactos">ReactOS</a> — Fork maintainer & <a
                        href="https://github.com/reactos/reactos/pulls?q=sort%3Aupdated-desc+author%3Aandrew-boyarshin">contributor</a>
                    </Text>
                    <Text as="p">
                        A free Windows-compatible Operating System, <Tooltip aria-label="Windows Vista and newer"
                                                                             className="tooltip-dotted">NT6</Tooltip>-targetting
                        fork with up to 62 <Tooltip aria-label="thousands of source code lines"
                                                    className="tooltip-dotted">KLoC</Tooltip> added and 23 KLoC
                        removed <Tooltip aria-label="excluding 3rd party dependencies code"
                                         className="tooltip-dotted">diff</Tooltip> <Tooltip
                        aria-label="partially duplicated between 2 branches" className="tooltip-dotted">across all
                        branches</Tooltip>.
                    </Text>
                    <Text as="p">
                        <StyledOcticon icon={CodeIcon} verticalAlign="middle" mr={1}/> C, C++, Windows Internals,
                        Windows
                        kernel development, reverse-engineering
                    </Text>
                </Box>

                <Heading className="no-break-after" fontSize={4}>
                    <StyledOcticon icon={MarkGithubIcon} verticalAlign="middle" mr={1}/> Other contributions of note
                </Heading>
                <Box className="no-break-block" mb={4}>
                    <Text as="p">
                        MSBuild — <Tooltip aria-label="Pull Request" className="tooltip-dotted">PR</Tooltip> <a
                        href="https://github.com/dotnet/msbuild/pull/5552">Expand properties in SdkReference on
                        evaluation
                        (#5552)</a>
                    </Text>
                    <Text as="p">
                        Released as part of .NET 5.0. Extends capabilities of MSBuild build platform, widely used in
                        Visual
                        Studio and .NET ecosystem.
                    </Text>
                    <Text as="p">
                        <StyledOcticon icon={CodeIcon} verticalAlign="middle" mr={1}/> C#
                    </Text>
                </Box>
                <Box className="no-break-block" mb={4}>
                    <Text as="p">
                        .NET — API Proposal <a href="https://github.com/dotnet/runtime/issues/31308">BigInteger.GetBitLength()
                        (#31308)</a>, <a href="https://github.com/dotnet/runtime/pull/37870">implementation <Tooltip
                        aria-label="Pull Request" className="tooltip-dotted">PR</Tooltip> (#37870)</a>
                    </Text>
                    <Text as="p">
                        Successful new standard library API proposal and implementation, <a
                        href="https://docs.microsoft.com/en-us/dotnet/api/system.numerics.biginteger.getbitlength">released</a> as
                        part of .NET 5.0. Enables fast and precise <span
                        dangerouslySetInnerHTML={{__html: log2}}/> computation.
                    </Text>
                    <Text as="p">
                        <StyledOcticon icon={CodeIcon} verticalAlign="middle" mr={1}/> C#
                    </Text>
                </Box>
                <Box className="no-break-block" mb={4}>
                    <Text as="p">
                        Gogs — <Tooltip aria-label="Pull Request" className="tooltip-dotted">PR</Tooltip> <a
                        href="https://github.com/gogs/gogs/pull/3233">Wiki mirroring implementation (#3233)</a>
                    </Text>
                    <Text as="p">
                        Enhanced repository mirroring for self-hosted Git service.
                    </Text>
                    <Text as="p">
                        <StyledOcticon icon={CodeIcon} verticalAlign="middle" mr={1}/> Go
                    </Text>
                </Box>
                <Box className="no-break-block" mb={6}>
                    <Text as="p">
                        <Tooltip aria-label="Fork of Gogs" className="tooltip-dotted">Gitea</Tooltip> — <Tooltip
                        aria-label="Pull Requests" className="tooltip-dotted">PRs</Tooltip> related to Markdown
                        rendering
                        (<a
                        href="https://github.com/go-gitea/gitea/pulls?q=sort%3Aupdated-desc+author%3Aandrew-boyarshin">#186,
                        #980, #1027, #1043</a>)
                    </Text>
                    <Text as="p">
                        More sophisticated Markdown post-processing for self-hosted Git service.
                    </Text>
                    <Text as="p">
                        <StyledOcticon icon={CodeIcon} verticalAlign="middle" mr={1}/> Go
                    </Text>
                </Box>

                <Heading className="no-break-after" fontSize={4}>
                    <StyledOcticon icon={PlusIcon} verticalAlign="middle" mr={1}/> Additional experience
                </Heading>
                <Box className="no-break-block" mb={6}>
                    <Text as="p">
                        Teaching C/C++/C# at <a href="https://ssyp.ru/">SSYP</a> (voluntary) and <a
                        href="http://www.education.nsu.ru/letka/">SESC</a> (paid) summer schools
                    </Text>
                    <Text as="p">
                        Once as a teacher assistant (<a href="https://github.com/ssyp-ru/ssyp18-ws07">SSYP-2018</a>), 3
                        times as a teacher (<a href="https://github.com/ssyp-ru/ssyp19-ws03">SSYP-2019</a>, <a
                        href="https://github.com/ssyp-ru/ssyp20-ws09">SSYP-2020</a>, SESC-2020) for 12-17 years old
                        students.
                    </Text>
                </Box>

                <Heading className="no-break-after" fontSize={4}>
                    <StyledOcticon icon={CodeSquareIcon} verticalAlign="middle" mr={1}/> Coding languages fluency
                </Heading>
                <Text as="p">
                    Good: C#, C
                </Text>
                <Text as="p">
                    Solid: C++
                </Text>
                <Text as="p">
                    Ordinary: Java, Python, JavaScript & TypeScript, UI markup (XAML)
                </Text>
                <Text as="p">
                    Sufficient: Go, Kotlin, Pascal, Lua, web markup (HTML & CSS)
                </Text>
                <Text as="p" mb={6}>
                    Basic: F#, PHP, .NET IL, x86 assembly, various others
                </Text>

                <Heading className="no-break-after" fontSize={4}>
                    <StyledOcticon icon={TypographyIcon} verticalAlign="middle" mr={1}/> Speaking languages fluency
                </Heading>
                <Text as="p" mb={6}>
                    Russian (native), English (<Tooltip
                    aria-label="Cambridge Certificate in Advanced English, Grade B, 2018-05-12"
                    className="tooltip-dotted">C1</Tooltip>), German (<Tooltip aria-label="DSD I, 2016"
                                                                               className="tooltip-dotted">B1</Tooltip>)
                </Text>

                <Heading className="no-break-after" fontSize={4}>
                    <StyledOcticon icon={MortarBoardIcon} verticalAlign="middle" mr={1}/> Educational achievements
                </Heading>
                <Text as="p">
                    University grades are consistently either "4" or "5" (out of 5).
                </Text>
                <Text as="p" mb={6}>
                    <a href="http://issc.nsu.ru/">International Scientific Student Conference 2020</a>, <a
                    href="http://issc.nsu.ru/it">IT section</a> — <Link to="/issc-20-thesis.pdf">"Model Checking and
                    Data-Flow Analysis in static source code analysis"</Link> — Grade 2 "Software architecture and
                    system programming" diploma. Scientific adviser — V. Solovyov.
                </Text>

                <Heading className="no-break-after" fontSize={4}>
                    <StyledOcticon icon={KebabHorizontalIcon} verticalAlign="middle" mr={1}/> Additional notes
                </Heading>
                <Text as="p">
                    Multiple <a href="http://neerc.secna.ru/school/index.php">school NEERC ACM ICPC</a> finals
                    participations as part of a 3 members team (twice as team
                    captain), Grade 2 Siberian region diploma in <a
                    href="http://neerc.secna.ru/school/2017/VKOSP_Barn_2017.htm">2017</a>.
                </Text>
                <Text as="p">
                    Well versed in navigating formal specifications, language standards.
                    The documentation (if existent) is always read first, before working on an implementation.
                </Text>
                <Text as="p">
                    Familiar with <span
                    dangerouslySetInnerHTML={{__html: tex}}/>, various shells (PowerShell, zsh, bash) and <Tooltip
                    aria-label="Version Control System" className="tooltip-dotted">SCM</Tooltip> tools (Git, SVN,
                    Mercurial).
                </Text>
                <Text as="p">
                    Lockdowns present no difficulties maintaining the productivity.
                </Text>
            </Box>
        </Layout>
    )
}